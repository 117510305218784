import { OTPModal } from "@/components/OTPModal";
import { useReauthentication } from "@/hooks/useReauthentication";
import axios from "@/services/AxiosWrapper";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { setSecondFactorRequired } from "@/state/slices/auth";
import { type FC, useEffect, useState } from "react";

/**
 * This component is responsible for handling the second factor authentication.
 * It should determine which type of second factor authentication is required and prompt the user to provide it through either:
 * - the ReauthenticationModal (for TOTP)
 * - the OTPModal (for OTP)
 */
export const SecondFactorAuthenticationModals: FC = () => {
	const dispatch = useAppDispatch();
	const { ReauthenticationModal } = useReauthentication();
	const { isSecondFactorRequired } = useAppSelector((state) => state.auth);
	const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);

	const runDeferredRequest = async () => {
		await axios.retryDeferredRequests();
		dispatch(setSecondFactorRequired(false));
		axios.clearOTP();
	};

	useEffect(() => {
		if (isSecondFactorRequired) {
			setIsOTPModalOpen(true);
		} else {
			setIsOTPModalOpen(false);
		}
	}, [isSecondFactorRequired]);

	return (
		<>
			{ReauthenticationModal}
			<OTPModal
				isOpen={isOTPModalOpen}
				onCancel={() => {
					setIsOTPModalOpen(false);
					dispatch(setSecondFactorRequired(false));
					axios.clearDeferredRequests();
				}}
				onSubmit={async (otp) => {
					axios.setOTP(otp);
					await runDeferredRequest();
				}}
			/>
		</>
	);
};
