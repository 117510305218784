import zapierIcon from "@/assets/integrations/logo_zapier.svg";
import humanitixIcon from "@/assets/logos/logo-icon-dark.svg";
import { H1, P } from "@/components/text/Text";
import { PageWrapper } from "@/oauth/Authorize";
import { Button } from "@/ui";
import { Flex } from "@/ui/antd";
import { SwapOutlined } from "@ant-design/icons";
import type { FC } from "react";

interface OAuthZapierProps {
	handleAuthorize: () => Promise<void>;
	isSubmitting: boolean;
}

export const OAuthZapier: FC<OAuthZapierProps> = ({ handleAuthorize, isSubmitting }) => {
	return (
		<PageWrapper>
			<Flex justify="center" align="center" gap="xl" style={{ marginBottom: "24px" }}>
				<img src={zapierIcon} alt="" />
				<SwapOutlined style={{ fontSize: "32px" }} />
				<img src={humanitixIcon} alt="" />
			</Flex>
			<H1>Authorize Zapier to connect to your Humanitix Account</H1>
			<P>After authorizing, Zapier will be able to access your events, attendees, and orders data.</P>

			<Button variant="primary" onClick={handleAuthorize} isLoading={isSubmitting} disabled={isSubmitting}>
				Authorize
			</Button>
		</PageWrapper>
	);
};
