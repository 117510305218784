import type { ThemeConfig } from "@/ui/antd";

export const themeConfig: ThemeConfig = {
	hashed: false,
	token: {
		colorPrimary: "#ffb18f",
		borderRadius: 2,
		fontFamily: "Satoshi",

		// // Alias Token
		// colorBgContainer: "#f6ffed"
		linkDecoration: "none"
	}
};
