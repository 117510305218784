import { LoadingSpinner } from "@/components/AppBlocks";
import H4 from "@/components/text/H4";
import P from "@/components/text/P";
import UserService from "@/services/UsersService";
import { Flex, Form, Input } from "@/ui/antd";
import { Button } from "@/ui/Button";
import { ResponsiveModal } from "@/ui/ResponsiveModal";
import { type FC, useEffect, useState } from "react";

export const OTPModal: FC<{
	isOpen: boolean;
	onCancel: () => void;
	onSubmit: (otp: string) => void;
}> = ({ onSubmit, isOpen, onCancel }) => {
	const [loading, setLoading] = useState(false);
	const [hasBeenResent, setHasBeenResent] = useState(false);
	const [otpForm] = Form.useForm();

	useEffect(() => {
		const generateOTP = async () => {
			if (isOpen) {
				setLoading(true);
				await UserService.generateOTP();
				setLoading(false);
			}
		};

		generateOTP();
	}, [isOpen]);

	const resendOTP = () => {
		UserService.generateOTP(true);
		setHasBeenResent(true);
	};

	const submit = () => {
		const { otp } = otpForm.getFieldsValue(true);
		otpForm.resetFields();
		onSubmit(otp);
	};

	return (
		<ResponsiveModal
			open={isOpen}
			header={<H4 style={{ marginBottom: 0 }}>Enter one time password</H4>}
			onCancel={onCancel}
			width="620px"
			footer={
				<Flex justify="space-between" wide>
					<Button aria-label="Resend OTP" variant="tertiary" onClick={resendOTP} disabled={hasBeenResent}>
						{hasBeenResent ? "Resent" : "Resend OTP"}
					</Button>

					<Button aria-label="Continue" variant="secondary" onClick={submit}>
						Continue
					</Button>
				</Flex>
			}
		>
			{loading ? (
				<LoadingSpinner style={{ margin: "16px auto", display: "block" }} />
			) : (
				<Form form={otpForm} autoComplete="off" onKeyUp={(e) => e.key === "Enter" && submit()}>
					<P>A one time password has been sent to the email associated with this account, please enter it below.</P>
					<Flex justify="center">
						<Form.Item name="otp" style={{ marginTop: 12 }}>
							<Input.OTP size="large" />
						</Form.Item>
					</Flex>
				</Form>
			)}
		</ResponsiveModal>
	);
};
