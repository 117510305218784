import App from "@/App";
import { AppProviders } from "@/AppProviders";
import "@/assets/styles/index.css";
import { BrowserRouter } from "@/BrowserRouter";
import { AppErrorBoundaries } from "@/errorHandling/AppErrorBoundaries";
import history from "@/history";
import { ReauthenticationProvider } from "@/hooks/useReauthentication";
import { unregister } from "@/registerServiceWorker";
import store from "@/state/store";
import { themeConfig } from "@/theme";
import { GlobalStyles } from "@/ui";
import { ConfigProvider as AntdConfigProvider, AntdOverrides, enGB } from "@/ui/antd";
import { createRoot } from "react-dom/client";

// TODO: This will come in once Pierre and Nick have aligned on the design tokens
// import { ColourV3Functions } from "@hx/colours";
// Inject the HX colour system into the app
// const tokens = ColourV3Functions.generateCssString("#ffb18f");
// const styleSheet = document.createElement("style");
// styleSheet.innerText = tokens;
// document.head.appendChild(styleSheet);

const root = createRoot(document.getElementById("root")!);
root.render(
	<AppErrorBoundaries>
		<AppProviders>
			<BrowserRouter history={history}>
				<AntdConfigProvider locale={enGB} theme={themeConfig}>
					<ReauthenticationProvider>
						<App />
						<GlobalStyles />
						<AntdOverrides />
					</ReauthenticationProvider>
				</AntdConfigProvider>
			</BrowserRouter>
		</AppProviders>
	</AppErrorBoundaries>
);

unregister();

if (window?.Cypress) {
	window.store = store;
}
