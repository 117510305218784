import { type FC, useEffect } from "react";
import { useLocation } from "react-router";
import { useAppSelector } from "@/state/hooks";
declare global {
	interface Window {
		Intercom: any;
		_hsq: any[];
	}
}

const HubspotTracking: FC = () => {
	const location = useLocation();
	const user = useAppSelector((state) => state.auth);

	useEffect(() => {
		if (user?.user) {
			const _hsq = (window._hsq = window._hsq || []);
			_hsq.push(["identify", { email: user?.user.email }]);
		}
	}, [user]);

	useEffect(() => {
		const _hsq = (window._hsq = window._hsq || []);
		_hsq.push(["setPath", location.pathname]);
		_hsq.push(["trackPageView"]);

		const script = document.createElement("script");
		script.src = `https://js.hs-scripts.com/4534422.js`;
		script.async = true;
		script.defer = true;
		script.type = "text/javascript";
		script.id = "hs-script-loader";

		document.body.appendChild(script);

		return () => {
			const existingScript = document.getElementById("hs-script-loader");
			if (existingScript) {
				document.body.removeChild(existingScript);
			}
		};
	}, [location]);

	return null;
};

export default HubspotTracking;
