import { type ComponentType, lazy } from "react";

export function lazyWithDelay<T extends Record<string, unknown>>(
	factory: () => Promise<T>,
	namedExportKey?: keyof T,
	minLoadTimeMs = 1500
) {
	return lazy(async () => {
		const [moduleExports] = await Promise.all([
			factory(),
			new Promise<void>((resolve) => setTimeout(resolve, minLoadTimeMs))
		]);

		if (namedExportKey) {
			if (!moduleExports[namedExportKey]) {
				throw new Error(
					`💀 Hey there, dev: You are probably calling lazyLoad.tsx with a named export key "${String(namedExportKey)}" that does not exist in the module`
				);
			}

			return { default: moduleExports[namedExportKey] as ComponentType<unknown> };
		}

		if (!moduleExports.default) {
			throw new Error(
				`💀 Hey there, dev: You are probably calling lazyLoad.tsx without a named export key and the module does not have a default export. HTH - the import is ${String(
					factory
				)}`
			);
		}

		return { default: moduleExports.default as ComponentType<unknown> };
	});
}
