import HubspotTracking from "@/components/HubspotTracking";
import MarkerIO from "@/components/MarkerIO";
import { getConfig } from "@/config";
import { SecondFactorAuthenticationModals } from "@/features/security/twofactorauthentication/SecondFactorAuthentication";
import { lazyLoad } from "@/hoc/lazyLoad";
import { lazyWithDelay } from "@/hoc/lazyWithDelay";
import { useAuth } from "@/hooks/useAuth";
import { OAuthAuthorize } from "@/oauth/Authorize";
import { useAppSelector } from "@/state/hooks";
import { WinkLoader } from "@/ui";
import { initialize } from "@hx/analytics";
import "antd/dist/reset.css";
import { type FC, Suspense, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

const AuthLayout = lazyWithDelay(() => import("@/auth/AuthLayout"), "AuthLayout");
const Authorize = lazyWithDelay(() => import("@/authorization/Authorize"));
const ConnectHubspot = lazyWithDelay(() => import("@/users/hubspot/ConnectHubspot"));
const ConnectMailchimp = lazyWithDelay(() => import("@/users/mailchimp/ConnectMailchimp"), "ConnectMailchimp");
const ConnectSalesforce = lazyWithDelay(() => import("@/users/salesforce/ConnectSalesforce"), "ConnectSalesforce");
const ConnectStripe = lazyWithDelay(() => import("@/events/event/payments/ConnectStripe"), "ConnectStripe");
const Console = lazyWithDelay(() => import("@/console/Console"), "Console");
const MicrosoftAuth = lazyWithDelay(() => import("@/auth/MicrosoftAuth"), "MicrosoftAuth");
const NotFound = lazyWithDelay(() => import("@/NotFound"), "NotFound");
const OnboardingOrganiser = lazyWithDelay(() => import("@/users/onboarding/Onboarding"));
const VerifyAccount = lazyWithDelay(() => import("@/auth/VerifyAccount"), "VerifyAccount");
const VerifyEmail = lazyWithDelay(() => import("@/auth/VerifyEmail"), "VerifyEmail");
const Signup = lazyWithDelay(() => import("@/auth/Signup"), "Signup");

// Anything wrapped in auth layout doesn't need delay
const ResetPassword = lazyLoad(() => import("@/auth/ResetPassword"), "ResetPassword");
const ForgotPassword = lazyLoad(() => import("@/auth/ForgotPassword"), "ForgotPassword");
const Signin = lazyLoad(() => import("@/auth/Signin"), "Signin");
const Login = lazyLoad(() => import("@/auth/Login"), "Login");

type PrivateRouteProps = {
	element: JSX.Element;
};

const PrivateRoute: FC<PrivateRouteProps> = ({ element }) => {
	const { authorized } = useAuth();
	const user = useAppSelector((state) => state.auth?.user);
	const location = useLocation();

	const requiresVerification = authorized && user && user.emailValid === false && user.emailVerified === false;
	const pathIsNotAtVerify =
		!location.pathname.includes("verify-account") && !location.pathname.includes("verify-email");

	return (
		<>
			{authorized === false && (
				<Navigate
					to={{
						pathname: "/signin",
						search: `?from=${encodeURIComponent(location.pathname + location.search)}`
					}}
					state={{ from: location }}
				/>
			)}
			{authorized && requiresVerification && pathIsNotAtVerify && <Navigate to="/verify-account" />}
			{authorized && element}
		</>
	);
};

const AccountManagementRedirection: FC = () => {
	const [searchParams] = useSearchParams();
	const redirectionMode = searchParams?.get("mode");
	const oobCode = searchParams?.get("oobCode") || "";

	switch (redirectionMode) {
		case "revertSecondFactorAddition":
			return <Navigate to={`/console/account/profile/security?oobCode=${oobCode}`} />;
		case "resetPassword":
			return <Navigate to={`/reset-password?oobCode=${oobCode}`} />;
		default:
			return <Navigate to="/" />;
	}
};

const App: FC = () => {
	const { userChecked, authorized } = useAuth();

	useEffect(() => {
		initialize(getConfig("USERFLUX_KEY"), "console");
	}, []);

	const winkLoader = useMemo(() => <WinkLoader fullscreen loading={false} />, [userChecked, authorized]);

	return (
		<>
			{winkLoader}
			{userChecked && (
				<Suspense fallback={winkLoader}>
					<MarkerIO />
					<HubspotTracking />
					<Routes>
						<Route path="/" element={<Navigate to="/console/dashboard" />} />
						<Route path="/authorize" element={<Authorize />} />
						<Route path="/connect-hubspot" element={<PrivateRoute element={<ConnectHubspot />} />} />
						<Route path="/connect-mailchimp" element={<PrivateRoute element={<ConnectMailchimp />} />} />
						<Route path="/connect-salesforce" element={<PrivateRoute element={<ConnectSalesforce />} />} />
						<Route path="/connect-stripe" element={<PrivateRoute element={<ConnectStripe />} />} />
						<Route path="/console/*" element={<PrivateRoute element={<Console />} />} />
						<Route path="/onboarding-organiser/:step" element={<PrivateRoute element={<OnboardingOrganiser />} />} />
						<Route path="/signup" element={<Signup />} />
						<Route element={<AuthLayout />}>
							<Route path="/account-management" element={<AccountManagementRedirection />} />
							<Route path="/signin" element={<Signin />} />
							<Route path="/login" element={<Login />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/reset-password" element={<ResetPassword />} />
						</Route>
						<Route path="/sso/microsoft" element={<MicrosoftAuth />} />
						<Route path="/verify-account/*" element={<PrivateRoute element={<VerifyAccount />} />} />
						<Route path="/verify-email/*" element={<PrivateRoute element={<VerifyEmail />} />} />
						<Route path="/oauth/authorize" element={<PrivateRoute element={<OAuthAuthorize />} />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
					<SecondFactorAuthenticationModals />
				</Suspense>
			)}
		</>
	);
};

export default App;
