import { ChunkLoadErrorBoundary, GeneralErrorBoundary } from "@/errorHandling";
import type { FC, ReactNode } from "react";

type AppErrorBoundariesProps = {
	children: ReactNode;
};

export const AppErrorBoundaries: FC<AppErrorBoundariesProps> = ({ children }) => {
	return (
		<GeneralErrorBoundary>
			<ChunkLoadErrorBoundary>{children}</ChunkLoadErrorBoundary>
		</GeneralErrorBoundary>
	);
};
