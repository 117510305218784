import "@/assets/styles/index.css";
import store from "@/state/store";
import { trpc, trpcReactClient } from "@/trpc";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { FC, ReactNode } from "react";
import { Provider as ReduxProvider } from "react-redux";

const queryClient = new QueryClient();
const { Provider: TrpcProvider } = trpc;

type AppProvidersProps = {
	children: ReactNode;
};

export const AppProviders: FC<AppProvidersProps> = ({ children }) => {
	return (
		<TrpcProvider client={trpcReactClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>
				<ReduxProvider store={store}>{children}</ReduxProvider>
			</QueryClientProvider>
		</TrpcProvider>
	);
};
