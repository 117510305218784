import humanitixIcon from "@/assets/logos/logo-icon-dark.svg";
import { P } from "@/components/text/Text";
import { getConfig } from "@/config";
import { OAuthZapier } from "@/oauth/Zapier";
import { notification } from "@/ui/antd";
import styled from "@emotion/styled";
import axios from "axios";
import { getAuth } from "firebase/auth";
import type { FC, ReactNode } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export const PageWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  width: 650px;
  text-align: center;

  h1 {
    max-width: 500px;
  }

  img {
    width: 60px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ErrorPageWrapper: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<PageWrapper>
			<div style={{ marginBottom: "24px" }}>
				<img src={humanitixIcon} alt="" />
			</div>
			{children}
		</PageWrapper>
	);
};

export const InvalidRequest: FC<{ redirectUri?: string | null; state?: string | null; clientId?: string | null }> = ({
	redirectUri,
	state,
	clientId
}) => {
	return (
		<ErrorPageWrapper>
			{!redirectUri && <P>Redirect URI is missing.</P>}
			{!state && <P>State is missing.</P>}
			{!clientId && <P>Client ID is missing.</P>}
		</ErrorPageWrapper>
	);
};

const UnrecognizedClientId: FC = () => {
	return (
		<ErrorPageWrapper>
			<P>Unrecognized Client ID.</P>
		</ErrorPageWrapper>
	);
};

export const OAuthAuthorize: FC = () => {
	const [searchParams] = useSearchParams();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const clientId = searchParams.get("client_id");
	const state = searchParams.get("state");
	const redirectUri = searchParams.get("redirect_uri");
	const authUrl = getConfig("AUTH_URL");
	const zapierClientId = getConfig("ZAPIER_OAUTH_CLIENT_ID");

	const handleAuthorize = async () => {
		setIsSubmitting(true);
		if (!clientId || !state || !redirectUri) return;

		const userToken = await getAuth().currentUser?.getIdToken(true);
		if (!userToken) return;

		try {
			const { data } = await axios.post(
				`${authUrl}/oauth/store-auth-code`,
				{
					clientId,
					redirectUri
				},
				{
					headers: {
						"x-token": userToken,
						"Content-Type": "application/json"
					}
				}
			);

			const code = data?.code;
			window.location.href = `${redirectUri}?state=${state}&code=${code}`;
		} catch (err) {
			notification.error({ message: "Error", description: "Failed to authorize" });
		}
	};

	if (!redirectUri || !clientId || !state) {
		return <InvalidRequest redirectUri={redirectUri} state={state} clientId={clientId} />;
	}

	if (clientId === zapierClientId) {
		return <OAuthZapier handleAuthorize={handleAuthorize} isSubmitting={isSubmitting} />;
	}

	return <UnrecognizedClientId />;
};
